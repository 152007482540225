import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import withLanguage from './utils/Language';
import FingerprintJS from 'fingerprintjs2';
import deviceService  from './services/DeviceService'
import PrivateRoute from './components/PrivateRoute'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const ForgotPassword = React.lazy(() => import('./views/pages/auth/ForgotPassword'));
const ForgotPasswordCode = React.lazy(() => import('./views/pages/auth/ForgotPasswordCode'));
const RecoverPassword = React.lazy(() => import('./views/pages/auth/RecoverPassword'));

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Page403 = React.lazy(() => import('./views/pages/page403/Page403'));

/*
https://getlinks.co/
https://eightfold.ai/talent-intelligence-platform/talent-management/
 */
class App extends Component {
  
  calcClientFingerprint() {
    if (localStorage.getItem("X-Zetgoo-Client-ID")) {
      return;
    }
    if (window.requestIdleCallback) {
      requestIdleCallback(function () {
        FingerprintJS.get(function (components) {
            console.log(components) // an array of components: {key: ..., value: ...}
            let values = components.map(function (component) { return component.value })
            let visitorId = FingerprintJS.x64hash128(values.join(''), 31)
            localStorage.setItem("X-Zetgoo-Client-Fingerprint",visitorId)
            deviceService.signUp({
              fingerprint: visitorId,
              components: components
            }).then(resObj => {
              if (resObj.status === "OK") {
                localStorage.setItem("X-Zetgoo-Client-ID",resObj.data)
              }
            });
          })
      })
    } else {
      setTimeout(function () {
        FingerprintJS.get(function (components) {
            console.log(components) // an array of components: {key: ..., value: ...}
            let values = components.map(function (component) { return component.value })
            let visitorId = FingerprintJS.x64hash128(values.join(''), 31)
            localStorage.setItem("X-Zetgoo-Client-Fingerprint",visitorId)
            //Request to Server for DeviceID
            deviceService.signUp({
              fingerprint: visitorId,
              components: components
            }).then(resObj => {
              if (resObj.status === "OK") {
                localStorage.setItem("X-Zetgoo-Client-ID",resObj.data)
              }
            });
          })  
      }, 500)
    }
  }
  componentDidMount() {
    this.calcClientFingerprint(); 
  }
  render() {
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props}/>} />
              <Route exact path="/forgot-password-code" name="Forgot Password Code" render={props => <ForgotPasswordCode {...props}/>} />
              <Route exact path="/recover-password" name="Recover Password" render={props => <RecoverPassword {...props}/>} />
              <Route exact path="/403" name="Page 403" render={props => <Page403 {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              {/*<Route path="/" name="Home" render={props => <TheLayout {...props}/>} />*/}
              <PrivateRoute path="/" name="Home" component={TheLayout} />

            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default withLanguage(App);
